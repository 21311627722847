import React, { useState, useEffect, Suspense, useContext, lazy, useRef } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import SingleDatePicker from "../DatePickers/SingleDatePicker/SingleDatePicker";
import DateRangePicker from "../DatePickers/DateRangePicker/DateRangePicker";
import LeftFilterBox from "../LeftFilterBox/LeftFilterBox";
import HarmonicAnalysisApi from "../../api/HarmonicAnalysisApi";
import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import EventsSortingTable from "../EventsSortingTable/EventsSortingTable";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FFTPlotParser } from "../../parser/HarmonicAnalysis";
import "../../css/HarmonicAnalysis.css";
const FFTPlot = lazy(() => import("../plots/FFTPlot"));

const HarmonicAmplitudeTrendingPlot = lazy(() => import("../plots/HarmonicAmplitudeTrendingPlot"));

const HATReportPlot = lazy(() => import("../plots/HatReportPlot"));

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

function getLfTolerance(harmonicFreqLf) {
    let tolerance;
    if ((0.0 <= harmonicFreqLf && harmonicFreqLf <= 0.99) || (1.01 <= harmonicFreqLf && harmonicFreqLf <= 3)) {
        tolerance = 0.008;
    } else if (0.99 <= harmonicFreqLf && harmonicFreqLf <= 1.01) {
        tolerance = 0.005;
    } else if (3.0 < harmonicFreqLf && harmonicFreqLf <= 5.0) {
        tolerance = 0.01;
    } else if (5.0 < harmonicFreqLf && harmonicFreqLf <= 10.0) {
        tolerance = 0.02;
    } else if (10.0 < harmonicFreqLf && harmonicFreqLf <= 20.0) {
        tolerance = 0.03;
    } else if (20.0 < harmonicFreqLf && harmonicFreqLf <= 30.0) {
        tolerance = 0.04;
    } else if (30.0 < harmonicFreqLf && harmonicFreqLf <= 60.0) {
        tolerance = 0.08;
    } else if (60.0 < harmonicFreqLf && harmonicFreqLf <= 80.0) {
        tolerance = 0.1;
    } else if (harmonicFreqLf > 80.0) {
        tolerance = 0.2;
    } else {
        tolerance = 0.3;
    }
    return tolerance;
}

function getHzTolerance(harmonicFreqHz) {
    let tolerance;
    if ((0.0 <= harmonicFreqHz && harmonicFreqHz <= 59.4) || (60.6 <= harmonicFreqHz && harmonicFreqHz <= 180)) {
        tolerance = 0.48;
    } else if (59.4 <= harmonicFreqHz && harmonicFreqHz <= 60.6) {
        tolerance = 0.36;
    }
    if (0.0 <= harmonicFreqHz && harmonicFreqHz <= 180) {
        tolerance = 0.36;
    } else if (180 < harmonicFreqHz && harmonicFreqHz <= 300) {
        tolerance = 0.6;
    } else if (300 < harmonicFreqHz && harmonicFreqHz <= 600) {
        tolerance = 1.2;
    } else if (600 < harmonicFreqHz && harmonicFreqHz <= 1200) {
        tolerance = 1.8;
    } else if (1200 < harmonicFreqHz && harmonicFreqHz <= 1800) {
        tolerance = 2.4;
    } else if (1800 < harmonicFreqHz && harmonicFreqHz <= 3600) {
        tolerance = 4.8;
    } else if (3600 < harmonicFreqHz && harmonicFreqHz <= 4800) {
        tolerance = 6.0;
    } else if (harmonicFreqHz > 4800) {
        tolerance = 12.0;
    } else {
        tolerance = 18.0;
    }
    return tolerance;
}

const subplotParametersAC = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Line Frequency", value: "Line Frequency" },
    { label: "Power Factor ZC", value: "Power Factor" },
    { label: "Power Factor", value: "Raw Power Factor" },
    { label: "Apparent Power (kVA)", value: "Apparent Power (kVA)" },
    { label: "Reactive Power (kVAR)", value: "Reactive Power (kVAR)" },
    { label: "Active Power (kW)", value: "Active Power (kW)" },
    { label: "Voltage Imbalance", value: "Voltage Imbalance" },
    { label: "Current Imbalance", value: "Current Imbalance" },
    { label: "Voltage THD", value: "Voltage THD" },
    { label: "Current THD", value: "Current THD" },
    { label: "V-Peaks", value: "V-Peaks" },
    { label: "GIS-Voltage", value: "GIS-Voltage" },
    { label: "Crest Factor", value: "Crest Factor" },
    { label: "HP ZC", value: "HP" },
    { label: "HP", value: "RAW HP" },
    { label: "Node Connected", value: "Node Connected" },
    { label: "Current Spike Count", value: "Current Spike Count" },
    { label: "Node Internal Temperature", value: "Node Internal Temperature" },
];

const subplotParametersDC = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Line Frequency", value: "Line Frequency" },
    { label: "V/I", value: "V/I" },
    {
        label: "V-Peaks",
        value: "V-Peaks",
    },
    { label: "HP", value: "HP" },
    { label: "Node Connected", value: "Node Connected" },
    { label: "Current Spike Count", value: "Current Spike Count" },
    { label: "Node Internal Temperature", value: "Node Internal Temperature" },
];

//Add these parameters for primary DC and v2 nodes to query the field node
const fieldParameters = [
    { label: "Field Voltage", value: "Field Voltage" },
    { label: "Field Current", value: "Field Current" },
    {
        label: (
            <>
                I/I<sub>field</sub>
            </>
        ),
        value: "I/Ifield",
    },
];

let subplotParameters = subplotParametersAC;

export default function HarmonicAnalysisTab() {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);
    const [activeTabKey, setActiveTabKey] = useState("Plot FFT");

    const [plotOrder, setPlotOrder] = useState({
        fft: 1,
        harmonic: 2,
        hat: 3,
    });

    // FFT Tab state hooks
    const [fftEsaList, setFFTEsaList] = useState([]);
    const [selectedFFTEsa, setselectedFFTEsa] = useState(null);
    const [fileType, setFileType] = useState("ESA");
    const [fileLocation, setFileLocation] = useState("Cloud File");
    const [eventTableRows, setEventTableRows] = useState([]);
    const [eventTableColumns, setEventTableColumns] = useState([]);
    const [eventTableData, setEventTableData] = useState({
        //eventsTableData: { rows: '', columns: '' },
        selectedEventFile: null,
        plotData: [],
        showPlotOnDoubleClick: false,
        plotMetaData: "",
        showPlot: false,
        isEventsCapturePlotLoading: false,
        currentSelectedTimeOnEventsCaptureTab: 0,
    });

    const [fftStartDate, setFFTStartDate] = useState(new Date());
    const [fftEndDate, setFFTEndDate] = useState(new Date());
    const [fftPlotAxisType, setFFTPlotAxisType] = useState("LF");
    const [fftPlotFreqValue, setFFTPlotFreqValue] = useState("low_frequency");
    const [fftPlotData, setFFTPlotData] = useState({
        V1: { x: "", y: "" },
        V2: { x: "", y: "" },
        V3: { x: "", y: "" },
        I1: { x: "", y: "" },
        I2: { x: "", y: "" },
        I3: { x: "", y: "" },
        lf_type: "",
        Title: "",
    });
    const [showFFTPlot, setShowFFTPlot] = useState(false);
    const [isFFTPlotLoading, setIsFFTPlotLoading] = useState(false);

    const [showFFTAlert, setShowFFTAlert] = useState(false);
    const [fftAlertColor, setFFTAlertColor] = useState();
    const [fftalertText, setFFTAlertText] = useState();

    const [fftParamTableData, setFFTParamTableData] = useState({
        columns: [{ dataField: "", text: "" }],
        rows: [],
    });

    const [fftStartStopTableData, setFFTStartStopTableData] = useState({
        columns: [{ dataField: "", text: "" }],
        rows: [],
    });

    const [fftParamTableDataTwo, setFFTParamTableDataTwo] = useState({
        columns: [{ dataField: "", text: "" }],
        rows: [],
    });

    const [fftStartStopTableDataTwo, setFFTStartStopTableDataTwo] = useState({
        columns: [{ dataField: "", text: "" }],
        rows: [],
    });

    const [fftPeaksFlag, setFFTPeaksFlag] = useState(false);
    const [fftPlotMetaData, setFFTPlotMetaData] = useState();

    const [fftDemodulateFlag, setFFTDemodulateFlag] = useState(false);
    const [fftCompareFlag, setFFTCompareFlag] = useState(false);
    const [fftFileOne, setFFTFileOne] = useState();
    const [fftFileTwo, setFFTFileTwo] = useState();
    const [fftFileOneEquipment, setFFTFileOneEquipment] = useState();
    const [fftFileTwoEquipment, setFFTFileTwoEquipment] = useState();
    const [fftRotorBarFlag, setFFTRotorBarFlag] = useState(false);
    const [fftYAxis, setFFTYAxis] = useState("LF");
    const [displayLabelsFlag, setDisplayLabelsFlag] = useState(false);

    // Harmonic Amplitude Trending State Hooks
    const [selectedXAxisType, setSelectedXAxisType] = useState("Current");
    const [harmonicStartDate, setHarmonicStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 3))
    );
    const [harmonicEndDate, setHarmonicEndDate] = useState(new Date());
    const [lfValue, setLFValue] = useState(0);
    const [harmonicTolerance, setharmonicTolerance] = useState();
    const [harmonicPlotData, setHarmonicPlotData] = useState({
        plot_data: {
            harmonics_phase_a: { x: "", y: "", name: "" },
            harmonics_phase_b: { x: "", y: "", name: "" },
            harmonics_phase_c: { x: "", y: "", name: "" },
            short_term_scan_results: { x: "", y: "", name: "" },
            long_term_scan_results: { x: "", y: "", name: "" },
            line_frequency: "",
            notes: {
                x: [],
                text: [],
            },

            Title: "",
            data: "",
            layout: "",
        },
        subplot_data: {},
    });

    const [harmonicScanPlotFlag, setHarmonicScanPlotFlag] = useState(false);
    const [displayOnResultsFlag, setDisplayOnResultsFlag] = useState(false);

    const [isHarmonicPlotLoading, setIsHarmonicPlotLoading] = useState(false);
    const [showHarmonicPlot, setShowHarmonicPlot] = useState(false);
    const [isLFValueInvalid, setIsLFValueInvalid] = useState(false);
    const [isHarmonicToleranceInvalid, setIsharmonicToleranceInvalid] = useState(false);
    const [showHarmonicAlert, setShowHarmonicAlert] = useState(false);
    const [harmonicAlertColor, setHarmonicAlertColor] = useState();
    const [harmonicAlertText, setHarmonicAlertText] = useState();
    const [harmonicAvereagePeriod, setHarmonicAveragePeriod] = useState("6 hour");
    const [harmonicSearchType, setHarmonicSearchType] = useState("LF");
    const [harmonicInputRange, setHarmonicInputRange] = useState(200);
    const [harmonicPlotMetaData, setHarmonicPlotMetaData] = useState({
        equipmentName: "",
        facilityName: "",
        harmonicType: "",
        harmonicLF: "",
        searchType: "",
        selectedParameters: "",
    });
    const [plotFromFFTData, setPlotFromFFTData] = useState();
    const [lfLowerBound, setLfLowerBound] = useState(null);
    const [lfUpperBound, setLfUpperBound] = useState(null);
    const lfLowerBoundInvalid = lfLowerBound && (lfLowerBound < 0 || lfLowerBound > (lfUpperBound ?? 120));
    const lfUpperBoundInvalid = lfUpperBound && (lfUpperBound < (lfLowerBound ?? 0) || lfUpperBound > 120);
    const parseLfBound = (val) => {
        const i = parseInt(val);
        if (isNaN(i)) return null;
        return i;
    };

    const calcHarmonicTolerance = () => {
        if (harmonicSearchType === "LF") {
            return getLfTolerance(lfValue ?? 0);
        } else {
            return getHzTolerance(lfValue ?? 0);
        }
    };
    const harmonicTolerancePlaceholder = calcHarmonicTolerance();

    const plotTopRef = useRef();

    const [selectedSubplotParameters, setSelectedSubplotParameters] = useState([]);
    //Setting plot parameters for AC vs DC
    useEffect(() => {
        if (selectedEquipment) {
            if (selectedEquipment.eq_type === "dc") {
                subplotParameters = subplotParametersDC;

                //Add Field parameters for primary nodes
                if (selectedEquipment.location_node_id.split(".")[1] == "0") {
                    subplotParameters = subplotParameters.concat(fieldParameters);
                }
            } else {
                subplotParameters = subplotParametersAC;

                //Add field parameters for V2s
                if (selectedEquipment.eq_type_sub === "v2") {
                    subplotParameters = subplotParameters.concat(fieldParameters);
                }
            }

            //Checks to see if previously selected parameters are still avilable and removes those which aren't
            let params = selectedSubplotParameters.filter((param) => {
                if (subplotParameters.some((el) => el.label === param.label)) {
                    return true;
                } else {
                    return false;
                }
            });
            setSelectedSubplotParameters(params);
        }
    }, [selectedEquipment]);

    // HAT Report Tab State Hooks
    const [hatVIParameters, setHatVIParameters] = useState("Current");
    const [hatDate, setHatDate] = useState(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 1));
    const [hatReportPlotData, setHATReportPlotData] = useState({});
    const [hatReportTableData, setHATReportTableData] = useState({
        table_data: "",
        columns: "",
    });
    const [isHATPlotLoading, setIsHATPlotLoading] = useState(false);
    const [showHATPlot, setShowHATPlot] = useState(false);
    const [showHATAlert, setShowHATAlert] = useState(false);
    const [hatAlertColor, setHATAlertColor] = useState();
    const [hatalertText, setHATAlertText] = useState();
    const [hatReportPlotMetaData, setHATReportMetaPlotData] = useState({});

    useEffect(() => {
        if (showSELMenu == true) {
            setFileLocation("Cloud File");
        }
    }, [showSELMenu]);

    useEffect(() => {
        if (fileLocation == "Node File" && fileType != "ESA") {
            setFileType("ESA");
        }
    }, [fileLocation]);

    //Conditions to disable the peaks flag
    useEffect(() => {
        if ((fileLocation == "Node File" || fftCompareFlag == true) && fftPeaksFlag == true) {
            setFFTPeaksFlag(false);
        }
    }, [fileLocation, fftCompareFlag]);

    const handleFileButtonClick = (fileNum, action) => {
        const file = action == "remove" ? null : selectedFFTEsa;
        const eq = action == "remove" || !file ? null : selectedEquipment;
        if (action == "add" && !selectedFFTEsa) {
            setFFTAlertColor("primary");
            setFFTAlertText("Select a file");
            setShowFFTAlert(true);
        }
        if (fileNum == 1) {
            setFFTFileOne(file);
            setFFTFileOneEquipment(eq);
        } else {
            setFFTFileTwo(file);
            setFFTFileTwoEquipment(eq);
        }
    };

    //Update the ESA List
    const validPromise = useRef(0);
    useEffect(() => {
        setFFTEsaList([]);
        setselectedFFTEsa();

        setEventTableColumns([]);
        setEventTableRows([]);

        setShowFFTAlert(false);

        if (selectedEquipment) {
            validPromise.current = validPromise.current + 1;
            const thisPromise = validPromise.current;

            const listPromise = () => {
                if (fileLocation == "Cloud File") {
                    if (fileType == "ESA") {
                        return HarmonicAnalysisApi.getEsaList(
                            token,
                            selectedEquipment.value,
                            formatDate(fftStartDate),
                            formatDate(fftEndDate),
                            equipmentTimezone.value,
                            selectedEquipment.product_type
                        );
                    } else {
                        return HarmonicAnalysisApi.getEventsList(
                            token,
                            selectedEquipment.value,
                            formatDate(fftStartDate),
                            formatDate(fftEndDate),
                            equipmentTimezone.value,
                            selectedEquipment.np_current,
                            true,
                            selectedEquipment.product_type,
                            selectedVoltageType.value
                        );
                    }
                } else {
                    return HarmonicAnalysisApi.getNodeESAList(
                        token,
                        selectedEquipment.value,
                        formatDate(fftStartDate),
                        equipmentTimezone.value
                    );
                }
            };

            // Api call for File list
            listPromise()
                .then((response) => {
                    if (thisPromise === validPromise.current) {
                        if (response.status === 204) {
                            setFFTAlertColor("primary");
                            setFFTAlertText(`No ${fileType} Found`);
                            setShowFFTAlert(true);
                        } else {
                            if (response.data.content == null) {
                                const text = response.data.info;
                                setFFTAlertColor("primary");
                                setFFTAlertText(text);
                                setShowFFTAlert(true);
                            } else {
                                setFFTEsaList(response.data.content.list_data);
                                setShowFFTAlert(false);
                                if (fileType == "Events") {
                                    setEventTableColumns(response.data.content.table_data.columns);
                                    setEventTableRows(response.data.content.table_data.rows);
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    setFFTAlertColor("primary");
                    setFFTAlertText("No ESA Found");
                    setShowFFTAlert(true);
                });
        }
    }, [equipmentTimezone, fileLocation, fileType, fftStartDate, fftEndDate, selectedEquipment]);

    //Table actions
    //Double click
    useEffect(() => {
        if (eventTableData.showPlot) {
            if (fftCompareFlag) {
                setFFTCompareFlag(false);
            } else {
                onPlotFFTButtonClick();
                setEventTableData((prevState) => {
                    return {
                        ...prevState,
                        showPlot: false,
                        showPlotOnDoubleClick: false,
                    };
                });
            }
        }
    }, [eventTableData, fftCompareFlag]);

    //Table Row select
    useEffect(() => {
        setselectedFFTEsa(eventTableData.selectedEventFile);
    }, [eventTableData]);

    useEffect(() => {
        setShowFFTAlert(false);

        // Clear Esa Dropdown
        setFFTEsaList([]);
        setselectedFFTEsa();

        setShowHATAlert(false);
        setShowFFTAlert(false);
        setShowHarmonicAlert(false);
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            // Reset Date Pickers
            if (equipStartDate > fftStartDate) {
                setFFTStartDate(new Date());
            }
            // set the date picker start date to 1 month back
            if (equipStartDate > harmonicStartDate) {
                const tempDate = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7);
                if (equipStartDate > tempDate) setHarmonicStartDate(equipStartDate);
                else setHarmonicStartDate(tempDate);

                setHarmonicEndDate(new Date());
            }
            if (equipStartDate > hatDate) {
                setHatDate(new Date());
            }
        }
    }, [selectedEquipment]);

    useEffect(() => {
        if (showFFTPlot) {
            onPlotFFTButtonClick();
        }
        if (showHarmonicPlot && selectedXAxisType === "Voltage") {
            onHarmonicPlotButtonClick();
        }
    }, [selectedVoltageType]);

    useEffect(() => {
        if (showSELMenu && activeTabKey !== "Plot FFT" && activeTabKey !== "Harmonic Amplitude Trending") {
            setActiveTabKey("Plot FFT");
        }
    }, [showSELMenu]);

    {
        /* **************************************** Plot FFT Tab Functions **************************************** */
    }

    useEffect(() => {
        if (fftRotorBarFlag && Number.isInteger(selectedEquipment?.np_rpm) == false) {
            setFFTRotorBarFlag(false);
            setFFTAlertColor("warning");
            setFFTAlertText("Rotor Bar feature not available, need npRPM");
            setShowFFTAlert(true);
        }
    }, [fftRotorBarFlag, selectedEquipment]);

    useEffect(() => {
        if (!fftCompareFlag) {
            setFFTFileOne();
            setFFTFileTwo();

            setFFTFileOneEquipment();
            setFFTFileTwoEquipment();
        }
    }, [fftCompareFlag]);

    useEffect(() => {
        // effect trigger when props changed from fftt plot by click and it start ploting the harmonic plot
        if (plotFromFFTData && plotFromFFTData.harmonicType !== "Rs") {
            plotHarmonicFromFFT(
                plotFromFFTData.selectedEquipment,
                plotFromFFTData.harmonic,
                plotFromFFTData.harmonicXAxisType,
                plotFromFFTData.harmonicType
            );
        }
    }, [plotFromFFTData]);

    useEffect(() => {
        setFFTPlotMetaData({
            ...fftPlotMetaData,
            fftDemodulateFlag: fftDemodulateFlag,
        });
    }, [fftDemodulateFlag]);

    const onPlotFFTButtonClick = () => {
        if (fftCompareFlag == true) {
            if (!fftFileOne || !fftFileTwo) {
                setFFTAlertColor("primary");
                setFFTAlertText("Select two files to compare");
                setShowFFTAlert(true);
            } else {
                setFFTPlotMetaData({
                    selectedVoltageType: selectedVoltageType,
                    selectedEquipment: selectedEquipment,
                    selectedFFTEsa: selectedFFTEsa,
                    fftFileOneEquipment: fftFileOneEquipment,
                    fftFileTwoEquipment: fftFileTwoEquipment,
                    selectedVoltageType: selectedVoltageType,
                    fftCompareFlag: fftCompareFlag,
                    fftFileOne: fftFileOne,
                    fftFileTwo: fftFileTwo,
                    eventTableRows: eventTableRows,
                    fftDemodulateFlag: fftDemodulateFlag,
                    fftRotorBarFlag: fftRotorBarFlag,
                    fftYAxis: fftYAxis,
                });
                setShowFFTAlert(false);
                setIsFFTPlotLoading(true);
                setFFTPlotData({
                    V1: { x: "", y: "" },
                    V2: { x: "", y: "" },
                    V3: { x: "", y: "" },
                    I1: { x: "", y: "" },
                    I2: { x: "", y: "" },
                    I3: { x: "", y: "" },
                    lf_type: "",
                    Title: "",
                });
                setFFTParamTableData({
                    columns: [{ dataField: "", text: "" }],
                    rows: [],
                });
                setFFTStartStopTableData({
                    columns: [{ dataField: "", text: "" }],
                    rows: [],
                });
                setShowFFTPlot(true);

                const firstFile = HarmonicAnalysisApi.get_fft_plot(
                    token,
                    fftFileOneEquipment.value,
                    fftFileOne.value,
                    fftPlotAxisType,
                    "one",
                    fftPlotFreqValue,
                    fftFileOneEquipment.vfd_driven,
                    fftPeaksFlag,
                    fftFileOneEquipment.np_rpm,
                    selectedVoltageType.value,
                    fftFileOneEquipment.product_type,
                    fftRotorBarFlag,
                    fftYAxis
                );
                const secondFile = HarmonicAnalysisApi.get_fft_plot(
                    token,
                    fftFileTwoEquipment.value,
                    fftFileTwo.value,
                    fftPlotAxisType,
                    "two",
                    fftPlotFreqValue,
                    fftFileTwoEquipment.vfd_driven,
                    fftPeaksFlag,
                    fftFileTwoEquipment.np_rpm,
                    selectedVoltageType.value,
                    fftFileTwoEquipment.product_type,
                    fftRotorBarFlag,
                    fftYAxis
                );

                Promise.all([firstFile, secondFile])
                    .then(([resp1, resp2]) => {
                        if (resp1.status === 200 && resp2.status === 200) {
                            // Remove unused channels for eq_type_sub v1
                            if (fftFileOneEquipment.eq_type_sub === "v1") {
                                resp1.data.content.plot_data.V2.x = [];
                                resp1.data.content.plot_data.V2.y = [];

                                resp1.data.content.plot_data.V3.x = [];
                                resp1.data.content.plot_data.V3.y = [];
                            }
                            // Remove unused channels for eq_type_sub v1
                            if (fftFileTwoEquipment.eq_type_sub === "v1") {
                                resp2.data.content.plot_data.V2.x = [];
                                resp2.data.content.plot_data.V2.y = [];

                                resp2.data.content.plot_data.V3.x = [];
                                resp2.data.content.plot_data.V3.y = [];
                            }

                            const data = {
                                plotData1: resp1.data.content.plot_data,
                                plotData2: resp2.data.content.plot_data,
                            };
                            const table1 = resp1.data.content.table_data;
                            const table2 = resp2.data.content.table_data;

                            table1.parameter_data.columns[0].text = "File 1";
                            table2.parameter_data.columns[0].text = "File 2";

                            setFFTPlotData(data);
                            setFFTParamTableData(table1.parameter_data);
                            setFFTStartStopTableData(table1.start_stop_data);
                            setFFTParamTableDataTwo(table2.parameter_data);
                            setFFTStartStopTableDataTwo(table2.start_stop_data);
                            setShowFFTPlot(true);
                            if (resp1.data.content.banner) {
                                setFFTAlertColor("primary");
                                setFFTAlertText(resp1.data.content.banner);
                                setShowFFTAlert(true);
                            } else if (resp2.data.content.banner) {
                                setFFTAlertColor("primary");
                                setFFTAlertText(resp2.data.content.banner);
                                setShowFFTAlert(true);
                            } else {
                                setShowFFTAlert(false);
                            }
                            setPlotOrder({
                                fft: 1,
                                harmonic: 2,
                                hat: 3,
                            });
                        }
                        setIsFFTPlotLoading(false);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        } else {
            if (!selectedCustomer) {
                setFFTAlertColor("primary");
                setFFTAlertText("Select a Customer");
                setShowFFTAlert(true);
            } else if (!selectedFacility) {
                setFFTAlertColor("primary");
                setFFTAlertText("Select a Facility ");
                setShowFFTAlert(true);
            } else if (!selectedEquipment) {
                setFFTAlertColor("primary");
                setFFTAlertText("Select an Equipment ");
                setShowFFTAlert(true);
            } else if (!selectedFFTEsa) {
                setFFTAlertColor("primary");
                setFFTAlertText("Select a ESA File");
                setShowFFTAlert(true);
            } else {
                setShowFFTAlert(false);
                setIsFFTPlotLoading(true);

                const plotTitle =
                    "Node - " +
                    selectedEquipment.label +
                    " - " +
                    selectedFacility.label +
                    "<br>Timestamp:" +
                    selectedFFTEsa.label;

                setFFTPlotMetaData({
                    selectedVoltageType: selectedVoltageType,
                    selectedEquipment: selectedEquipment,
                    selectedFFTEsa: selectedFFTEsa,
                    fftCompareFlag: fftCompareFlag,
                    eventTableRows: eventTableRows,
                    fftDemodulateFlag: fftDemodulateFlag,
                    fftRotorBarFlag: fftRotorBarFlag,
                    fftYAxis: fftYAxis,
                });
                // 						showPlot={showFFTPlot}
                setFFTPlotData({
                    V1: { x: "", y: "" },
                    V2: { x: "", y: "" },
                    V3: { x: "", y: "" },
                    I1: { x: "", y: "" },
                    I2: { x: "", y: "" },
                    I3: { x: "", y: "" },
                    lf_type: "",
                    Title: "",
                });
                setFFTParamTableData({
                    columns: [{ dataField: "", text: "" }],
                    rows: [
                        {
                            "": " ",
                            Ia: 0,
                            Ib: 0,
                            Ic: 0,
                            Vab: 0,
                            Vbc: 0,
                            Vca: 0,
                        },
                    ],
                });
                setFFTStartStopTableData({
                    columns: [{ dataField: "", text: "" }],
                    rows: [{ "": "", Count: 0 }],
                });
                setShowFFTPlot(true);

                HarmonicAnalysisApi.get_fft_plot(
                    token,
                    selectedEquipment.value,
                    selectedFFTEsa.value,
                    fftPlotAxisType,
                    plotTitle,
                    fftPlotFreqValue,
                    selectedEquipment.vfd_driven,
                    fftPeaksFlag,
                    selectedEquipment.np_rpm,
                    selectedVoltageType.value,
                    selectedEquipment.product_type,
                    fftRotorBarFlag,
                    fftYAxis
                )
                    .then((resp) => {
                        if (resp.status === 200) {
                            // Remove unused channels for eq_type_sub v1
                            if (selectedEquipment.eq_type_sub === "v1") {
                                resp.data.content.plot_data.V2.x = [];
                                resp.data.content.plot_data.V2.y = [];

                                resp.data.content.plot_data.V3.x = [];
                                resp.data.content.plot_data.V3.y = [];
                            }
                            setFFTPlotData(resp.data.content.plot_data);
                            setFFTParamTableData(resp.data.content.table_data.parameter_data);
                            setFFTStartStopTableData(resp.data.content.table_data.start_stop_data);
                            setShowFFTPlot(true);
                            setIsFFTPlotLoading(false);
                            if (resp.data.content.banner) {
                                setFFTAlertColor("primary");
                                setFFTAlertText(resp.data.content.banner);
                                setShowFFTAlert(true);
                            } else {
                                setShowFFTAlert(false);
                            }
                            setPlotOrder({
                                fft: 1,
                                harmonic: 2,
                                hat: 3,
                            });
                        }
                        setIsFFTPlotLoading(false);
                    })
                    .catch((error) => {
                        setIsFFTPlotLoading(false);
                        console.log(error);
                    });
            }
        }
    };

    {
        /* **************************************** Harmonic Amp Trending Tab Functions **************************************** */
    }

    const onHarmonicStartDateChange = (date) => {
        setHarmonicStartDate(date);
    };
    const onHarmonicEndDateChange = (date) => {
        setHarmonicEndDate(date);
    };

    const onDisplayFlagChange = (checked) => {
        if (checked) {
            setDisplayOnResultsFlag(true);
            setHarmonicAveragePeriod("15 min");
        } else {
            setDisplayOnResultsFlag(false);
        }
    };

    useEffect(() => {
        let temp_var = 200;
        if (harmonicSearchType === "Hz") {
            setHarmonicInputRange(10000);
            temp_var = 10000;
        } else {
            setHarmonicInputRange(200);
            temp_var = 200;
        }

        if (lfValue > temp_var) setIsLFValueInvalid(true);
        else setIsLFValueInvalid(false);
    }, [harmonicSearchType]);

    const onLFCahnge = (value) => {
        const val = parseFloat(value);
        setLFValue(parseFloat(value));
        if (val > 0 && val <= harmonicInputRange) {
            setIsLFValueInvalid(false);
        } else {
            setIsLFValueInvalid(true);
        }
    };

    const onToleranceChange = (value) => {
        const val = parseFloat(value);
        setharmonicTolerance(parseFloat(value));
        if (((val) => 0 && val <= 10) || isNaN(val)) {
            setIsharmonicToleranceInvalid(false);
        } else {
            setIsharmonicToleranceInvalid(true);
        }
    };

    const onHarmonicPlotButtonClick = () => {
        if (!selectedCustomer) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Select a Customer");
            setShowHarmonicAlert(true);
        } else if (!selectedFacility) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Select a Facility ");
            setShowHarmonicAlert(true);
        } else if (!selectedEquipment) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Select an Equipment");
            setShowHarmonicAlert(true);
        } else if (!(lfValue > 0 && lfValue <= harmonicInputRange)) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Enter LF Value");
            setShowHarmonicAlert(true);
        } else if (lfLowerBoundInvalid || lfUpperBoundInvalid) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Invalid LF Bounds");
            setShowHarmonicAlert(true);
        } else {
            setPlotOrder({
                fft: 2,
                harmonic: 1,
                hat: 3,
            });
            setHarmonicPlotMetaData({
                selectedEquipment: selectedEquipment,
                selectedNodes: [selectedEquipment],
                equipmentName: selectedEquipment.label,
                facilityName: selectedFacility.label,
                harmonicType: selectedXAxisType,
                harmonicLF: lfValue,
                searchType: harmonicSearchType,
                selectedVoltageType: selectedVoltageType,
                selectedParameters: selectedSubplotParameters,
                startDate: formatDate(harmonicStartDate),
                endDate: formatDate(harmonicEndDate),
            });
            setShowHarmonicAlert(false);
            setShowHarmonicPlot(true);
            setIsHarmonicPlotLoading(true);
            if (selectedEquipment.product_type === "SEL") {
                HarmonicAnalysisApi.get_sel_harmonic_plot(
                    token,
                    selectedEquipment.value,
                    formatDate(harmonicStartDate),
                    formatDate(harmonicEndDate),
                    lfValue,
                    selectedXAxisType,
                    harmonicAvereagePeriod,
                    harmonicSearchType,
                    equipmentTimezone.value,
                    harmonicTolerance,
                    selectedSubplotParameters
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(false);
                        } else {
                            setHarmonicPlotData(response.data.content);
                            setLFValue(response.data.content.search_harmonic);
                            setHarmonicPlotMetaData({
                                ...harmonicPlotMetaData,
                                isPlotLoading: false,
                            });
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                        }
                    })
                    .catch((error) => {
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            } else {
                if (selectedXAxisType === "Demodulated") {
                    HarmonicAnalysisApi.getDemodulatedPlotData(
                        token,
                        selectedEquipment.value,
                        formatDate(harmonicStartDate),
                        formatDate(harmonicEndDate),
                        lfValue,
                        harmonicSearchType,
                        harmonicAvereagePeriod,
                        equipmentTimezone.value,
                        selectedVoltageType.value,
                        harmonicTolerance
                    )
                        .then((response) => {
                            if (response.status === 204) {
                                setIsHarmonicPlotLoading(false);
                                setShowHarmonicPlot(false);
                            } else {
                                setPlotOrder({
                                    fft: 2,
                                    harmonic: 1,
                                    hat: 3,
                                });
                                setHarmonicPlotData(response.data.content);
                                setIsHarmonicPlotLoading(false);
                                setShowHarmonicPlot(true);
                            }
                        })
                        .catch((error) => {
                            setIsHarmonicPlotLoading(false);
                            console.log(error);
                        });
                } else {
                    HarmonicAnalysisApi.get_harmonic_amp_trending_plot(
                        token,
                        selectedEquipment.value,
                        formatDate(harmonicStartDate),
                        formatDate(harmonicEndDate),
                        lfValue,
                        selectedXAxisType,
                        harmonicAvereagePeriod,
                        harmonicSearchType,
                        selectedEquipment.starter == "VFD",
                        equipmentTimezone.value,
                        selectedVoltageType.value,
                        harmonicTolerance,
                        harmonicScanPlotFlag,
                        displayOnResultsFlag,
                        displayLabelsFlag,
                        selectedSubplotParameters,
                        selectedEquipment.work_cycle,
                        lfLowerBound,
                        lfUpperBound
                    )
                        .then((response) => {
                            if (response.status === 204) {
                                setIsHarmonicPlotLoading(false);
                                setShowHarmonicPlot(false);
                            } else {
                                setPlotOrder({
                                    fft: 2,
                                    harmonic: 1,
                                    hat: 3,
                                });
                                setHarmonicPlotData(response.data.content);
                                setIsHarmonicPlotLoading(false);
                                setShowHarmonicPlot(true);
                            }
                        })
                        .catch((error) => {
                            setIsHarmonicPlotLoading(false);
                            console.log(error);
                        });
                }
            }
        }
    };

    const plotHarmonicFromFFT = (selectedEquipmentInfo, harmonicLF, axisType, trendType) => {
        if (!isHarmonicPlotLoading) {
            setPlotOrder({
                fft: 2,
                harmonic: 1,
                hat: 3,
            });
            setIsHarmonicPlotLoading(true);

            setHarmonicPlotMetaData({
                selectedEquipment: selectedEquipment,
                selectedNodes: [selectedEquipment],
                equipmentName: selectedEquipmentInfo.label,
                facilityName: selectedEquipmentInfo.facility_name,
                harmonicType: axisType,
                harmonicLF: harmonicLF,
                searchType: trendType,
                selectedVoltageType: selectedVoltageType,
                isPlotLoading: true,
                selectedParameters: [],
            });
            setShowHarmonicPlot(true);

            setHarmonicSearchType(trendType);
            setLFValue(harmonicLF);
            setSelectedXAxisType(axisType);
            if (selectedEquipmentInfo.product_type === "SEL") {
                HarmonicAnalysisApi.get_sel_harmonic_plot(
                    token,
                    selectedEquipmentInfo.value,
                    formatDate(harmonicStartDate),
                    formatDate(harmonicEndDate),
                    harmonicLF,
                    axisType,
                    harmonicAvereagePeriod,
                    trendType,
                    equipmentTimezone.value,
                    harmonicTolerance
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(false);
                        } else {
                            setHarmonicPlotData(response.data.content);
                            setLFValue(response.data.content.search_harmonic);
                            setHarmonicPlotMetaData({
                                ...harmonicPlotMetaData,
                                isPlotLoading: false,
                            });
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                        }
                    })
                    .catch((error) => {
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            } else {
                HarmonicAnalysisApi.get_harmonic_amp_trending_plot(
                    token,
                    selectedEquipmentInfo.value,
                    formatDate(harmonicStartDate),
                    formatDate(harmonicEndDate),
                    harmonicLF,
                    axisType,
                    harmonicAvereagePeriod,
                    trendType,
                    selectedEquipmentInfo.starter == "VFD",
                    equipmentTimezone.value,
                    selectedVoltageType.value,
                    harmonicTolerance,
                    harmonicScanPlotFlag,
                    displayLabelsFlag,
                    selectedSubplotParameters,
                    selectedEquipment.work_cycle,
                    null,
                    null
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(false);
                        } else {
                            setHarmonicPlotData(response.data.content);
                            setLFValue(response.data.content.search_harmonic);
                            setHarmonicPlotMetaData({
                                ...harmonicPlotMetaData,
                                isPlotLoading: false,
                            });
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                        }
                    })
                    .catch((error) => {
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            }
        }
    };

    {
        /* **************************************** HAT Report Tab Functions **************************************** */
    }

    const onHatDateChange = (date) => {
        setHatDate(date);
    };

    const onHatPlotButtonClick = () => {
        if (!selectedCustomer) {
            setHATAlertColor("primary");
            setHATAlertText("Select a Customer");
            setShowHATAlert(true);
        } else if (!selectedFacility) {
            setHATAlertColor("primary");
            setHATAlertText("Select a Facility ");
            setShowHATAlert(true);
        } else if (!selectedEquipment) {
            setHATAlertColor("primary");
            setHATAlertText("Select an Equipment");
            setShowHATAlert(true);
        } else {
            setShowHATAlert(false);
            setPlotOrder({
                fft: 3,
                harmonic: 2,
                hat: 1,
            });
            setIsHATPlotLoading(true);

            setHATReportMetaPlotData({
                selectedEquipment: selectedEquipment,
                equipmentName: selectedEquipment.label,
                facilityName: selectedFacility.label,
                date: formatDate(hatDate),
                hatVIParameters: hatVIParameters,
            });
            setHATReportPlotData({
                Impact: [],
                change_y: [],
                harmonic_lf_x: [],
                hover_data: [],
                size_ref: 0,
            });
            setHATReportTableData({
                columns: [{ dataField: "", text: "" }],
                rows: [{}],
            });
            setShowHATPlot(true);

            HarmonicAnalysisApi.get_hat_report_plot(
                token,
                selectedFacility.value,
                selectedEquipment.label,
                selectedEquipment.value,
                formatDate(hatDate),
                hatVIParameters,
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsHATPlotLoading(false);
                        setHATAlertColor("primary");
                        setHATAlertText("No Data Found");
                        setShowHATAlert(true);
                        setShowHATPlot(false);
                    } else {
                        setHATReportPlotData(response.data.content.plot_data);
                        setHATReportTableData(response.data.content.table_data);
                        setIsHATPlotLoading(false);
                        setShowHATPlot(true);
                        setPlotOrder({
                            fft: 3,
                            harmonic: 2,
                            hat: 1,
                        });
                    }
                })
                .catch((error) => {
                    setIsHATPlotLoading(false);
                    console.log(error);
                });
        }
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col className="col-8">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="Plot FFT"
                                        activeKey={activeTabKey}
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            setActiveTabKey(tabName);
                                        }}
                                    >
                                        {/* **************************************** Plot FFT Tab **************************************** */}
                                        <Tab eventKey="Plot FFT" title="Plot FFT">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Plot FFT</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="harmonic-date">
                                                    <Row>
                                                        <Col>
                                                            <label>Select Date Range:</label>
                                                            <br></br>
                                                            <DateRangePicker
                                                                startDate={fftStartDate}
                                                                endDate={fftEndDate}
                                                                onStartDateChange={setFFTStartDate}
                                                                onEndDateChange={setFFTEndDate}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <label>Select X-Axis Type:</label>
                                                    <br></br>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Col sm={10}>
                                                                <Form.Check
                                                                    checked={fftPlotAxisType === "LF"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    value="LF"
                                                                    label="LF"
                                                                />
                                                                <Form.Check
                                                                    checked={fftPlotAxisType === "Hz"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Hz"
                                                                    value="Hz"
                                                                />
                                                                {!showSELMenu ? (
                                                                    <Form.Check
                                                                        checked={fftPlotAxisType === "Rs"}
                                                                        onChange={(val) =>
                                                                            setFFTPlotAxisType(val.target.value)
                                                                        }
                                                                        type="radio"
                                                                        label="Rs"
                                                                        value="Rs"
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            {!showSELMenu ? (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <label>Select File Type:</label>
                                                            <Form.Group inline="true">
                                                                <Form.Check
                                                                    inline="true"
                                                                    checked={fileType == "ESA"}
                                                                    onChange={(val) => {
                                                                        setFileType(val.target.value);
                                                                    }}
                                                                    type="radio"
                                                                    label="ESA"
                                                                    value="ESA"
                                                                    disabled={fileLocation == "Node File"}
                                                                />
                                                                <Form.Check
                                                                    inline="true"
                                                                    type="radio"
                                                                    value="Events"
                                                                    checked={fileType == "Events"}
                                                                    label="Events"
                                                                    onChange={(val) => {
                                                                        setFileType(val.target.value);
                                                                    }}
                                                                    disabled={fileLocation == "Node File"}
                                                                />
                                                            </Form.Group>
                                                            <br />
                                                        </Col>
                                                        <Col>
                                                            <label>Select File Location:</label>
                                                            <Form.Group inline="true">
                                                                <Form.Check
                                                                    inline="true"
                                                                    checked={fileLocation == "Cloud File"}
                                                                    onChange={(val) => {
                                                                        setFileLocation(val.target.value);
                                                                    }}
                                                                    type="radio"
                                                                    label="Cloud"
                                                                    value="Cloud File"
                                                                    disabled={fileType == "Events"}
                                                                />
                                                                <Form.Check
                                                                    inline="true"
                                                                    type="radio"
                                                                    value="Node File"
                                                                    checked={fileLocation == "Node File"}
                                                                    label="Node"
                                                                    onChange={(val) => {
                                                                        setFileLocation(val.target.value);
                                                                    }}
                                                                    disabled={fileType == "Events"}
                                                                />
                                                            </Form.Group>
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Row>
                                                <Col className="col-6">
                                                    <label>Select File:</label>
                                                    <br></br>
                                                    <Dropdown
                                                        options={fftEsaList}
                                                        value={selectedFFTEsa}
                                                        onChange={(value) => {
                                                            setShowFFTAlert(false);
                                                            setselectedFFTEsa(value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Select FFT Range:</label>
                                                    <br></br>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Col sm={10}>
                                                                <Form.Check
                                                                    checked={fftPlotFreqValue === "low_frequency"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotFreqValue(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Low Frequency"
                                                                    value="low_frequency"
                                                                />
                                                                <Form.Check
                                                                    checked={fftPlotFreqValue === "medium_frequency"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotFreqValue(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Medium Frequency"
                                                                    value="medium_frequency"
                                                                />
                                                                {!showSELMenu ? (
                                                                    <Form.Check
                                                                        checked={fftPlotFreqValue === "high_frequency"}
                                                                        onChange={(val) =>
                                                                            setFFTPlotFreqValue(val.target.value)
                                                                        }
                                                                        type="radio"
                                                                        label="High Frequency"
                                                                        value="high_frequency"
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            {fftCompareFlag ? (
                                                <>
                                                    <Row>
                                                        <Col className="col-6">
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            File 1:{" "}
                                                                            {fftFileOneEquipment
                                                                                ? `SN:${fftFileOneEquipment.node_sn}`
                                                                                : ""}
                                                                        </Col>
                                                                        <Col
                                                                            style={{
                                                                                textAlign: "right",
                                                                            }}
                                                                        >
                                                                            <button
                                                                                style={{
                                                                                    background: "inherit",
                                                                                    border: "none",
                                                                                    padding: "0!important",
                                                                                    color: "#007bff",
                                                                                    "text-decoration": "underline",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    const action = fftFileOne
                                                                                        ? "remove"
                                                                                        : "add";
                                                                                    handleFileButtonClick(1, action);
                                                                                }}
                                                                            >
                                                                                {fftFileOne ? "Remove" : "Add File"}
                                                                            </button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div
                                                                                style={{
                                                                                    position: "relative",
                                                                                    overflow: "hidden",
                                                                                    "background-color": "white",
                                                                                    border: "1px solid #ccc",
                                                                                    "border-radius": "2px",
                                                                                    "box-sizing": "border-box",
                                                                                    color: "#333",
                                                                                    cursor: "default",
                                                                                    outline: "none",
                                                                                    padding: "8px 52px 8px 10px",
                                                                                    transition: "all 200ms ease",
                                                                                }}
                                                                            >
                                                                                {fftFileOne
                                                                                    ? fftFileOne.label
                                                                                    : "File 1"}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            File 2:{" "}
                                                                            {fftFileTwoEquipment
                                                                                ? `SN:${fftFileTwoEquipment.node_sn}`
                                                                                : ""}
                                                                        </Col>
                                                                        <Col
                                                                            style={{
                                                                                textAlign: "right",
                                                                            }}
                                                                        >
                                                                            <button
                                                                                style={{
                                                                                    background: "inherit",
                                                                                    border: "none",
                                                                                    padding: "0!important",
                                                                                    color: "#007bff",
                                                                                    "text-decoration": "underline",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    const action = fftFileTwo
                                                                                        ? "remove"
                                                                                        : "add";
                                                                                    handleFileButtonClick(2, action);
                                                                                }}
                                                                            >
                                                                                {fftFileTwo ? "Remove" : "Add File"}
                                                                            </button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div
                                                                                style={{
                                                                                    position: "relative",
                                                                                    overflow: "hidden",
                                                                                    "background-color": "white",
                                                                                    border: "1px solid #ccc",
                                                                                    "border-radius": "2px",
                                                                                    "box-sizing": "border-box",
                                                                                    color: "#333",
                                                                                    cursor: "default",
                                                                                    outline: "none",
                                                                                    padding: "8px 52px 8px 10px",
                                                                                    transition: "all 200ms ease",
                                                                                }}
                                                                            >
                                                                                {fftFileTwo
                                                                                    ? fftFileTwo.label
                                                                                    : "File 2"}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                onChange={(event) => {
                                                                    setFFTPeaksFlag(event.target.checked);
                                                                }}
                                                                type="checkbox"
                                                                label="FFT Peaks"
                                                                checked={fftPeaksFlag}
                                                                disabled={
                                                                    fileLocation == "Node File" ||
                                                                    fftCompareFlag == true
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                onChange={(event) => {
                                                                    setFFTCompareFlag(event.target.checked);
                                                                }}
                                                                type="checkbox"
                                                                label="Compare FFT"
                                                                checked={fftCompareFlag}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                onChange={(event) => {
                                                                    setFFTDemodulateFlag(event.target.checked);
                                                                }}
                                                                type="checkbox"
                                                                label="Demodulation"
                                                                checked={fftDemodulateFlag}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                onChange={(event) => {
                                                                    setFFTRotorBarFlag(event.target.checked);
                                                                }}
                                                                type="checkbox"
                                                                label="Rotor Bar Frequencies"
                                                                checked={fftRotorBarFlag}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <label>Select Y-Axis Type:</label>
                                                    <br></br>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Col sm={10}>
                                                                <Form.Check
                                                                    checked={fftYAxis === "LF"}
                                                                    onChange={(val) => setFFTYAxis(val.target.value)}
                                                                    type="radio"
                                                                    value="LF"
                                                                    label="LF"
                                                                />
                                                                <Form.Check
                                                                    checked={fftYAxis === "dB"}
                                                                    onChange={(val) => setFFTYAxis(val.target.value)}
                                                                    type="radio"
                                                                    label="dB"
                                                                    value="dB"
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-2">
                                                    {isFFTPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onPlotFFTButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-5 harmonicAlert">
                                                    <Alert
                                                        variant={fftAlertColor}
                                                        show={showFFTAlert}
                                                        onClose={() => setShowFFTAlert(false)}
                                                        dismissible
                                                    >
                                                        {fftalertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>

                                        {/* **************************************** Harmonic Amplitude Trending Tabs **************************************** */}
                                        <Tab eventKey="Harmonic Amplitude Trending" title="Harmonic Amplitude Trending">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Harmonic Amplitude Trending</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="harmonic-date">
                                                    <label>Select Date Range:</label>

                                                    <DateRangePicker
                                                        startDate={harmonicStartDate}
                                                        endDate={harmonicEndDate}
                                                        onStartDateChange={onHarmonicStartDateChange}
                                                        onEndDateChange={onHarmonicEndDateChange}
                                                    />
                                                </Col>
                                                <Col className="fit-col-two">
                                                    <label>Select X-Axis Type:</label>

                                                    <Col className="col-6">
                                                        <Form.Group as={Row}>
                                                            <Col sm={10} className="margin_left_-15">
                                                                <Form.Check
                                                                    checked={selectedXAxisType === "Current"}
                                                                    onChange={(val) =>
                                                                        setSelectedXAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Current"
                                                                    value="Current"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    value="Voltage"
                                                                    checked={selectedXAxisType === "Voltage"}
                                                                    label="Voltage"
                                                                    onChange={(val) =>
                                                                        setSelectedXAxisType(val.target.value)
                                                                    }
                                                                />
                                                                {showSELMenu ? null : (
                                                                    <Form.Check
                                                                        type="radio"
                                                                        value="Demodulated"
                                                                        checked={selectedXAxisType === "Demodulated"}
                                                                        label="Demodulated Current"
                                                                        onChange={(val) =>
                                                                            setSelectedXAxisType(val.target.value)
                                                                        }
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col className="col-3">
                                                    <label>Trend Harmonic:</label>
                                                    <Form.Group inline="true">
                                                        <Form.Check
                                                            inline="true"
                                                            checked={harmonicSearchType === "LF"}
                                                            onChange={(val) => {
                                                                setHarmonicSearchType(val.target.value);
                                                            }}
                                                            type="radio"
                                                            label="LF"
                                                            value="LF"
                                                        />
                                                        <Form.Check
                                                            inline="true"
                                                            type="radio"
                                                            value="Hz"
                                                            checked={harmonicSearchType === "Hz"}
                                                            label="Hz"
                                                            onChange={(val) => {
                                                                setHarmonicSearchType(val.target.value);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <br></br>
                                                    <Form.Control
                                                        type="number"
                                                        min={0}
                                                        max={harmonicInputRange}
                                                        value={lfValue}
                                                        onChange={(newLF) => onLFCahnge(newLF.target.value)}
                                                        isInvalid={isLFValueInvalid}
                                                        required
                                                    />
                                                    <br></br>
                                                </Col>
                                                <Col className="col-2">
                                                    <div className="empty-box-spacing"></div>
                                                    <br></br>
                                                    <label>Tolerance:</label>
                                                    <Form.Control
                                                        type="number"
                                                        min={0}
                                                        max={10}
                                                        value={harmonicTolerance}
                                                        onChange={(newTolerance) =>
                                                            onToleranceChange(newTolerance.target.value)
                                                        }
                                                        isInvalid={isHarmonicToleranceInvalid}
                                                        placeholder={harmonicTolerancePlaceholder}
                                                    />
                                                    <br></br>
                                                </Col>
                                                <Col className="grow-col"></Col>
                                                <Col className="fit-col-two">
                                                    <label>Average Period:</label>

                                                    <Col className="col">
                                                        <Form.Group as={Row}>
                                                            <Col sm={10} className="margin_left_-15">
                                                                <Form.Check
                                                                    checked={harmonicAvereagePeriod === "6 hour"}
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="6 hours"
                                                                    value="6 hour"
                                                                    disabled={displayOnResultsFlag}
                                                                />
                                                                <Form.Check
                                                                    checked={harmonicAvereagePeriod === "12 hour"}
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="12 hours"
                                                                    value="12 hour"
                                                                    disabled={displayOnResultsFlag}
                                                                />
                                                                <Form.Check
                                                                    checked={harmonicAvereagePeriod === "24 hour"}
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="24 hours"
                                                                    value="24 hour"
                                                                    disabled={displayOnResultsFlag}
                                                                />
                                                                <Form.Check
                                                                    checked={harmonicAvereagePeriod === "15 min"}
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="None"
                                                                    value="15 min"
                                                                    disabled={displayOnResultsFlag}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-3">
                                                    <label>LF Lower Bound (Hz):</label>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="120"
                                                        value={lfLowerBound}
                                                        onChange={(val) =>
                                                            setLfLowerBound(parseLfBound(val.target.value))
                                                        }
                                                        isInvalid={lfLowerBoundInvalid}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter a value between 0 and {lfUpperBound ?? "120"}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col className="col-3">
                                                    <label>LF Upper Bound (Hz):</label>
                                                    <Form.Control
                                                        type="number"
                                                        min={lfLowerBound ?? 0}
                                                        max={120}
                                                        value={lfUpperBound}
                                                        onChange={(val) =>
                                                            setLfUpperBound(parseLfBound(val.target.value))
                                                        }
                                                        isInvalid={lfUpperBoundInvalid}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter a value between {lfLowerBound ?? "0"} and 120
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <p>Add Subplots:</p>
                                                    <Select
                                                        isMulti
                                                        isClearable
                                                        defaultValue={[]}
                                                        value={selectedSubplotParameters}
                                                        onChange={(value) => setSelectedSubplotParameters(value)}
                                                        options={subplotParameters}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        onChange={(event) => {
                                                            setHarmonicScanPlotFlag(event.target.checked);
                                                        }}
                                                        type="checkbox"
                                                        label="Display Harmonic Scan Peaks"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        onChange={(event) => {
                                                            //setHarmonicScanPlotFlag(event.target.checked);
                                                            onDisplayFlagChange(event.target.checked);
                                                        }}
                                                        type="checkbox"
                                                        label="Display Results with Waveforms"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        onChange={(event) => {
                                                            setDisplayLabelsFlag(event.target.checked);
                                                        }}
                                                        type="checkbox"
                                                        label="Display Equipment Notes"
                                                        checked={displayLabelsFlag}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    {isHarmonicPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onHarmonicPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}{" "}
                                                </Col>
                                                <Col className="col-5 harmonicAlert">
                                                    <Alert
                                                        variant={harmonicAlertColor}
                                                        show={showHarmonicAlert}
                                                        onClose={() => setShowHarmonicAlert(false)}
                                                        dismissible
                                                    >
                                                        {harmonicAlertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {/* **************************************** HAT Report Tab **************************************** */}
                                        <Tab
                                            eventKey="HAT Report"
                                            title="HAT Report"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">HAT Report</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="harmonic-date">
                                                    <label>Select Date:</label>
                                                    <br></br>
                                                    <SingleDatePicker
                                                        startDate={hatDate}
                                                        onDateChange={onHatDateChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Select Parameters:</label>
                                                    <br></br>
                                                    <Form.Group as={Row}>
                                                        <Col sm={10}>
                                                            <Form.Check
                                                                checked={hatVIParameters === "Current"}
                                                                onChange={(val) => setHatVIParameters(val.target.value)}
                                                                type="radio"
                                                                label="Current"
                                                                value="Current"
                                                            />
                                                            <Form.Check
                                                                type="radio"
                                                                value="Voltage"
                                                                checked={hatVIParameters === "Voltage"}
                                                                label="Voltage"
                                                                onChange={(val) => setHatVIParameters(val.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col className="col-2">
                                                    {isHATPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onHatPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}{" "}
                                                </Col>
                                                <Col className="col-5 harmonicAlert">
                                                    <Alert
                                                        variant={hatAlertColor}
                                                        show={showHATAlert}
                                                        onClose={() => setShowHATAlert(false)}
                                                        dismissible
                                                    >
                                                        {hatalertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            <Suspense fallback={<div>Loading..</div>}>
                <EventsSortingTable
                    rows={eventTableRows}
                    columns={eventTableColumns}
                    setTabData={setEventTableData}
                    tabData={eventTableData}
                    selectedEquipment={selectedEquipment}
                />
                <br></br>
            </Suspense>
            {/********************************************** Plot Section ***************************************************************************/}
            {/* <i className='test' ref={plotTopRef} >{ }</i> */}
            <Suspense fallback={<div>Loading..</div>}>
                <Row ref={plotTopRef}>
                    <Col lg={{ order: plotOrder.fft, span: 12 }}>
                        {showFFTPlot ? (
                            <FFTPlot
                                fftPlotData={fftPlotData}
                                paramsTableData={fftParamTableData}
                                startStopTableData={fftStartStopTableData}
                                setFFTPlotData={setFFTPlotData}
                                setShowPlot={setShowFFTPlot}
                                showPlot={showFFTPlot}
                                plotMetaData={fftPlotMetaData}
                                setPlotFromFFTData={setPlotFromFFTData}
                                isPlotLoading={isFFTPlotLoading}
                                paramsTableDataTwo={fftParamTableDataTwo}
                                startStopTableDataTwo={fftStartStopTableDataTwo}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotOrder.harmonic, span: 12 }}>
                        {showHarmonicPlot ? (
                            <HarmonicAmplitudeTrendingPlot
                                harmonicPlotData={harmonicPlotData}
                                setHarmonicPlotData={setHarmonicPlotData}
                                setShowPlot={setShowHarmonicPlot}
                                showPlot={showHarmonicPlot}
                                isPlotLoading={isHarmonicPlotLoading}
                                plotMetaData={harmonicPlotMetaData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotOrder.hat, span: 12 }}>
                        {showHATPlot ? (
                            <HATReportPlot
                                plotData={hatReportPlotData}
                                setHATReportPlotData={setHATReportPlotData}
                                plotMetaData={hatReportPlotMetaData}
                                setShowPlot={setShowHATPlot}
                                showPlot={showHATPlot}
                                tableData={hatReportTableData}
                                isPlotLoading={isHATPlotLoading}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
